import request from '@/common/request.js';
import cloneDeep from 'lodash/cloneDeep';
// 登录
export async function userSession({ }, data) {
    let result = await request({
        url: '/users/session',
        method: 'post',
        data
    })
    return result
}


export async function logOut({ }, data) {
    let result = await request({
        url: '/users/log-out',
        method: 'post',
        data
    })
    return result
}

// 用户列表
export async function getUserList({ }, params) {
    let data = await request({
        url: '/users/list',
        params
    })
    return data;
}
// 添加用户
export async function addUser({ }, data) {
    await request({
        url: '/users/add',
        method: 'post',
        data
    })
}
// 修改用户
export async function editUser({ }, data) {
    let depData = cloneDeep(data)
    delete depData.id
    await request({
        url: `/users/${data.id}`,
        method: 'put',
        data: depData
    })
}
// 个人信息
export async function getUserInfo({ }, params) {
    let data = await request({
        url: '/users/info',
        params,
    })
    return data;
}
// offer列表
export async function getOfferList({ }, params) {
    let data = await request({
        url: '/offer/list',
        params
    })
    return data;
}
// 获取执行频率
export async function getUsersCron({ }, params) {
    let data = await request({
        url: '/users/cron',
        methods: 'get',
        params
    })
    return data;
}
// 设置执行频率
export async function setUsersCron({ }, data) {
    await request({
        url: '/users/cron',
        method: 'post',
        data
    })

}
// 设置调价状态
export async function changeOfferStatus({ }, data) {
    let result = await request({
        url: '/offer/status',
        method: 'post',
        data
    })
    return result
}
// 获取调价规则
export async function getOfferRule({ }, {
    offerSku,
    shopId,
}) {
    let data = await request({
        url: `/offer/rule`,
        params: {
            shopId,
            offerSku
        }
    })
    return data;
}
// 设置调价规则
export async function setOfferRule({ }, { data }) {
    let result = await request({
        url: `/offer/rule`,
        method: 'post',
        data
    })
    return result
}
// 同步信息
export async function getInfoSync({ }, data) {
    await request({
        url: '/sync/info',
        method: 'post',
        data
    })
}
// 批量创建跟卖
export async function batchSetOffer({ }, data) {
    let result = await request({
        url: '/offer/batch-upload',
        method: 'post',
        data
    })
    return result
}

// 批量导入 offer
export async function batchEan({ }, data) {
    let result = await request({
        url: '/offer/batch-ean',
        method: 'post',
        data
    })
    return result
}

// 批量调整Offer配置
export async function batchUpdateOffer({ }, data) {
    let result = await request({
        url: '/offer/batch-update',
        method: 'post',
        data
    })
    return result
}
// 批量导出
export async function batchExportAction({ }, data) {
    let result = await request({
        url: '/offer/excel-data',
        method: 'post',
        data
    })
    return result
}
// 查看跟卖信息
export async function getOfferDetail({ }, params) {
    let data = await request({
        url: '/offer/detail',
        params
    })
    return data;
}
// 批量删除
export async function batchDeleteAction({ }, data) {
    let result = await request({
        url: '/offer/batch-delete',
        method: 'post',
        data
    })
    return result
}

export async function batchDeleteActionAdmin({ }, data) {
    let result = await request({
        url: '/admin/batch-delete',
        method: 'post',
        data
    })
    return result
}

export async function getShopList({},params) {
    const result = await request({
        url: '/shop/list',
        method: 'get',
        params
    })
    return result;  
}

export async function deleteShop({}, data) {
    const result = await request({
        url: '/shop/delete',
        method: 'post',
        data
    })

    return result
}

export async function updateUserInfo({}, data) {
    let result = await request({
        url: '/users/info',
        method: 'put',
        data
    })
    return result
}

export async function updateShop({}, data) {
    let result = await request({
        url: '/shop/update',
        method: 'post',
        data
    })
    return result
}

export async function updateShopSwitch({}, data) {
    let result = await request({
        url: '/shop/update-switch',
        method: 'post',
        data
    })
    return result
}

export async function getAdminShopList({}, params) {
    const result = await request({
        url: '/admin/shop-list',
        method: 'get',
        params
    })
    return result;  
}

export async function getAdminOfferList({},params) {
    let data = await request({
        url: '/admin/offer-list',
        params
    })
    return data;
}

export async function deleteUser({}, data) {
    const result = await request({
        url: '/users/delete',
        method: 'post',
        data
    })

    return result
}

export async function updateApiKeyList({}, data) {
    let result = await request({
        url: '/shop/apikey-pool',
        method: 'post',
        data
    })
    return result
}

export async function getApiKeyList({},params) {
    let data = await request({
        url: '/shop/apikey-pool',
        params
    })
    return data;
}

export async function markOffer({}, data) {
    let result = await request({
        url: '/offer/onepass',
        method: 'post',
        data
    })
    return result
}

export async function updateCatchShopTaxStatus({}, data) {
    let result = await request({
        url: '/users/update-catch-tax',
        method: 'post',
        data
    })
    return result
}

export async function deduplicateAction({}, data) {
    let result = await request({
        url: '/offer/deduplicate',
        method: 'post',
        data
    })
    return result
}

export async function batchUpdateQuality({}, data) {
    let result = await request({
        url: '/offer/update-quality',
        method: 'post',
        data
    })
    return result
}

export async function getOrderList({}, params) {
    let data = await request({
        url: '/order/list',
        params
    })
    return data;
}

export async function syncOrders({}, data) {
    let result = await request({
        url: '/order/sync',
        method: 'post',
        data
    })
    return result
}

export async function createInvoice({}, data) {
    let result = await request({
        url: '/order/create-invoice',
        method: 'post',
        data
    })
    return result
}

export async function batchUploadInvoice({}, data) {
    let result = await request({
        url: '/order/batch-upload-invoice',
        method: 'post',
        data
    })
    return result
}


export async function getProductList({}, params) {
    let data = await request({
        url: '/product/list',
        params
    })
    return data;
}

export async function deleteProduct({}, data) {
    let result = await request({
        url: '/product/delete',
        method: 'post',
        data
    })
    return result
}

export async function getProductDetail({}, params) {
    let data = await request({
        url: '/product/detail',
        params
    })
    return data;
}
// 导出产品数据
export async function exportProducts({}, params) {
    const result = await request({
        url: '/product/download',
        method: 'get',
        download: true,  // 指定响应类型为blob
        params  // 使用 params 而不是 data
    })
    return result
}

// 批量更新发货天数
export async function batchUpdateLeadtime({}, data) {
    let result = await request({
        url: '/offer/batch-update-leadtime',
        method: 'post',
        data
    })
    return result
}

// 获取调价历史
export async function getOfferPriceHistory({}, params) {
    let data = await request({
        url: '/offer/price-history',
        params
    })
    return data;
}